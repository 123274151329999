<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    hasSeparator?: boolean,
    path?: string
  }>(),
  {
    hasSeparator: false,
    path: '#'
  }
)

const emit = defineEmits<{
  (e: 'click', event: any): void
}>()
</script>

<template>
  <li :class="{ 'has-separator': hasSeparator }">
    <a :href="props.path" @click.prevent="emit('click', $event)">
      <slot />
    </a>
  </li>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

$border-radius: 4px;

li {
  transition: all .3s ease;
  width: 100%;

  &.has-separator:not(:last-child):after {
    background: mix($main-gray-color, white, 20%);
    content: '';
    display: block;
    height: 1px;
    margin: 8px 0;
  }

  & > a {
    align-items: center;
    box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    display: flex;
    padding: 8px 16px;
    text-decoration: none;

    &:hover {
      background: mix($main-gray-color, white, 20%);
    }

    & > :deep(svg) {
      margin-right: 8px;
    }
  }
}
</style>
